<template>
  <main class="register-reseller w-100 blank-aside-js" :class = "(isOpenAside=='true')?'blank-aside':''">
    <section class="register-reseller__numbers">
      <span class="register-reseller__number register-reseller__number_active">1</span>
      <span class="register-reseller__number register-reseller__number_active">2</span>
      <span class="register-reseller__number">3</span>
      <span class="register-reseller__number">4</span>
    </section>
    <h1 class="title title__h1 register-reseller__title">{{ $t("Marketer Registration: Owner Details and Login") }}</h1>
    <p class="text register-reseller__text">{{ $t("Please enter the following details") }}</p>
    <section class="reseller-owner register-reseller__reseller-owner">
      <div class="reseller-owner__container">
        <form class="reseller-owner__cover">
          <div class="reseller-owner__wrapper">
            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">*{{ $t("First name") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("Last Name") }} *</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("ID number / passport") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("Mobile Phone") }} *</label>
              <input class="input" type="tel" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("Home address") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("Username") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("E-mail") }}</label>
              <input class="input" type="email" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("Postal Code") }}</label>
              <input class="input" type="text" />
            </div>

            <div class="reseller-owner__item">
              <label class="label reseller-owner__label">{{ $t("Password") }}</label>
              <input class="input" type="text" />
            </div>
          </div>
        </form>

        <button class="button reseller-owner__button">
          <div class="reseller-owner__plus"></div>{{ $t("Adding additional owners") }}
        </button>
      </div>
      <a href="register-reseller-2.html" class="link reseller-owner__link">{{ $t("Continue") }}</a>
    </section>
  </main>
</template>

<script>
export default {
  name: "RegisterReseller2",
};
</script>
<style lang="scss" scoped>
.ltr-type {
  .reseller-owner__plus {
    margin-left: unset;
    margin-right: 16px;
  }
}
</style>